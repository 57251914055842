



















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

@Component
export default class EditIndex extends Vue {
  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }
}
